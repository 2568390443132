import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import { styled } from '@mui/material/styles';
import backgroundImg from './images/BeachBackground.png'
import { Box, Typography } from '@mui/material';

const BeachBackground = styled('img')(({ theme }) => ({
  width: '1920px',
  // [theme.breakpoints.down('sm')]: {
  //   maxWidth: '120%',
  // },
}))

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  height: '100vh',
  backgroundColor: '#25282a',
}))

function App() {
  return (
    <div className="App">
      <section>
        <HeaderWrapper>
          <BeachBackground aria-hidden="true" src={backgroundImg} alt="" />
        </HeaderWrapper>
        <Box>
          <Typography variant='h1' color='white'>
            Matt Johnson
          </Typography>
        </Box>
      </section>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header> */}
    </div>
  );
}

export default App;
